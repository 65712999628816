<template>
  <div class="vue-box">
    <div class="c-panel">
      <!-- 参数栏 -->
      <div class="c-title">支付宝分账订单管理</div>
      <el-form :inline="true" size="mini">
        <el-form-item>
          <el-button type="success" icon="el-icon-refresh" @click="
              p.page = 1;
              f5();
            ">刷新</el-button>
        </el-form-item>
        <br />
      </el-form>
      <el-table :data="dataList" size="mini" :header-cell-style="{ 'text-align': 'center' }" :cell-style="{ 'text-align': 'center' }">
        <el-table-column label="分账订单" prop="tradeNo" width="240px">
        </el-table-column>
        <el-table-column label="接收支付宝账号" prop="shareAccount" width="150px"></el-table-column>
        <el-table-column label="商户单号" prop="outTradeNo" width="230px"></el-table-column>
        <el-table-column label="系统单号" prop="transactionId" width="200px"></el-table-column>
        <el-table-column label="订单金额" width="100px">
          <template slot-scope="s">
            <el-tag type="success" size="mini" effect="dark">￥{{ s.row.amount }}</el-tag>
          </template>
        </el-table-column>
        <el-table-column label="分账金额" prop="shareAmonut">
          <template slot-scope="s">
            <el-tag type="success" size="mini" effect="dark">￥{{ s.row.shareAmonut }}</el-tag>
          </template>
        </el-table-column>
        <el-table-column label="状态" width="80px">
          <template slot-scope="s">
            <el-tag v-if="s.row.shareStatus == 2" type="danger" size="mini" effect="dark">分账失败</el-tag>
            <el-tag v-if="s.row.shareStatus == 1" type="success" size="mini" effect="dark">分账成功</el-tag>
            <el-tag v-if="s.row.shareStatus == 0" type="warning" size="mini" effect="dark">未分账</el-tag>
          </template>
        </el-table-column>
        <el-table-column label="创建时间" prop="createdTime" width="200px"></el-table-column>
        <!-- <el-table-column label="退款状态" width="80px">
          <template slot-scope="s">
            <el-tag v-if="s.row.refundStatus == 1" type="success" size="mini" effect="dark">退款成功</el-tag>
            <el-tag v-if="s.row.refundStatus == 0" type="warning" size="mini" effect="dark">可以退款</el-tag>
          </template>
        </el-table-column> -->
        <el-table-column prop="address" label="操作" width="150px" fixed="right">
          <template slot-scope="s">
            <el-button class="c-btn" type="primary" icon="el-icon-thumb" @click="show(s.row)">进行分账</el-button>
            <!-- <el-button class="c-btn" type="primary" icon="el-icon-thumb" @click="refund(s.row)">进行退款</el-button> -->
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页 -->
      <div class="page-box">
        <el-pagination background layout="total, prev, pager, next, sizes" :current-page.sync="p.page" :page-size.sync="p.limit" :total="dataCount" :page-sizes="[1, 10, 20, 30, 40, 50, 100]" @current-change="f5(true)" @size-change="f5(true)">
        </el-pagination>
      </div>
    </div>
    <el-drawer :append-to-body="true" :visible.sync="drawerVisible" size="28%" direction="rtl">
      <div>
        <el-form label-width="120px">
          <el-form-item label="收款账号:" placeholder="请选择">
            <el-select v-model="m.shareAccountId" @click.native="getAcconutList()">
              <el-option v-for="item in accountList" :key="item.loginName" :label="item.shareName" :value="item.id" />
            </el-select>
          </el-form-item>
          <el-form-item>
            <span class="dialog-footer">
              <el-button @click="hidect()">取 消</el-button>
              <el-button type="primary" @click="share()">提 交</el-button>
            </span>
          </el-form-item>
        </el-form>
      </div>
    </el-drawer>
  </div>
</template>

<script>

export default {
  data() {
    return {
      m: {
        shareOrderId: null,
        shareAccountId: null,
      },
      p: {
        page: 1,
        limit: 10,
      },
      data: null,
      drawerVisible: false,
      dataCount: 0,
      dataList: [],
      accountList: []
    };
  },
  methods: {
    // 数据刷新
    f5: function () {
      var defaultCfg = {
        type: "get", // 默认请求类型
      };
      this.sa.ajax(
        "/v1/alipay_profitshare_order/list",
        this.p,
        function (res) {
          this.dataList = res.data.records; // 数据
          this.dataCount = res.data.total; // 分页
        }.bind(this),
        defaultCfg
      );
    },
    show: function (data) {
      this.drawerVisible = true
      this.data = data;
    },
    share: function () {
      var defaultCfg = {
        type: "get", // 默认请求类型
      };
      let self = this;
      this.m.shareOrderId = this.data.id
      this.sa.ajax(
        "/v1/alipay_profitshare_order/requestShareOrder",
        this.m,
        function () {
          self.sa.alert("提交成功", function () {
            self.drawerVisible = false;
            self.f5();
          });
        },
        defaultCfg
      );
    },
    refund: function (data) {
      var defaultCfg = {
        type: "get", // 默认请求类型
      };
      this.m.shareOrderId = data.id
      let self = this;
      this.sa.ajax(
        "/v1/alipay_profitshare_order/refund",
        this.m,
        function () {
          self.sa.alert("提交成功", function () {
            self.f5();
          });
        },
        defaultCfg
      );
    },
    getAcconutList: function () {
      var p = {
        page: 1,
        limit: 10,
      };
      var defaultCfg = {
        type: "get", // 默认请求类型
        msg: null
      };
      this.sa.ajax(
        "/v1/alipay_profitshare_account/list",
        p,
        function (res) {
          this.accountList = res.data.records; // 数据
        }.bind(this),
        defaultCfg
      );
    },
    hidect: function () {
      this.drawerVisible = false;
    }
  },
  created: function () {
    this.f5();
  },
};
</script>

<style>
</style>
